import router from '@/router'
import RasaSessionsService from '@/services/sessions'
import RasaConversationService from '@/services/conversation'
import { mountSessionById } from "@/assets/js/rasaBuildConversations"
import { Debug } from '@sentry/integrations'

const state = {
    sessions: [],
    sessionsPendingCount: {total: 0},
    statusToShow: 'PENDING',
    loading: false,
    error: false,
    lastCallCountTime: new Date(),
    temporaryLoader: 0,
    prioritySessionsSource: null,
    qtdSessionsToPush: 10,
}

const mutations = {
    setSessions(state, payload) {
        state.sessions = payload
    },

    createSession(state, payload) {
        if (['PENDING', 'WAITING_TREATMENT'].includes(payload?.session?.status)) {
            state.sessions.push(payload)
        }
    },
    setTemporaryLoader(state, payload) {
        state.temporaryLoader = payload
    },

    setLoading(state, payload) {
        state.loading = payload
    },

    setError(state, payload) {
        state.error = payload
    },

    setLastCallCountTime(state, value) {
        state.lastCallCountTime = value
    },

    setSessionStatus(state, idx, new_status) {
        state.sessions[idx].session.status = new_status
    },

    deleteSession(state, idx) {
        state.sessions.splice(idx, 1)
    },

    orderSessions(state) {
        state.sessions = state.sessions.sort((session1, session2) => {
          if(session1.session.created_at > session2.session.created_at) return -1
          return 1
        })
    },

    updatePendingsCount(state, counts) {
        state.sessionsPendingCount = counts
    },

    pushMessage(state, payload) {
        for (const idx in state.sessions) {
            if (state.sessions[idx].session.id == payload.session_id) {
                state.sessions[idx].messages.push(payload)
                break
            }
        }   
    },

    pushManyMessages(state, payload) {
        for (const idx in state.sessions) {
            if (state.sessions[idx].session.id == payload.id) {
                state.sessions[idx].messages = payload.messages
            }
        }
    },

    updateLastMessage(state, payload) {
        let session = state.sessions.find(conversation => conversation.session.id == payload.session_id)
        session.last_message = payload
    },

    setStatusToShow(state, status){
        state.statusToShow = status
    },

    setHasUnread(state, payload){
        state.sessions[payload.idx].session.has_unread = payload.has_unread     
    },

    setMessageStatus(state, event){
        let msg_idx = state.sessions[event.idx].messages.indexOf(
            state.sessions[event.idx].messages.find((obj) => obj.id == event.message.message_id && obj.type == event.message.message_type)
        )
        if (msg_idx > -1) {
            if (event.message.attribute == 'seen_at') {
                state.sessions[event.idx].messages[msg_idx].seen_at = event.message.updated_at
            } else if (event.message.attribute == 'sent_at') {
                state.sessions[event.idx].messages[msg_idx].sent_at = event.message.updated_at
            } else if (event.message.attribute == 'error_at') {
                state.sessions[event.idx].messages[msg_idx].error_at = event.message.updated_at
            }
        }
    },
    setPrioritySessionsSource(state, source) {
        state.prioritySessionsSource = source
    },
    setQtdSessionsToPush(state, newValue) {
        state.qtdSessionsToPush = parseInt(newValue) 
    }
}

const actions = {
	async getSessions({ rootState, commit, state, dispatch }, payload) { 
        commit("setTemporaryLoader", payload.sessions.length)      
        for (const idx in payload.sessions) {
            const already_exist = state.sessions.find((conversation) => conversation.session.id == payload.sessions[idx].id)
            if (!already_exist) {
                const conversation = await mountSessionById(payload.sessions[idx].id, router.app._route.params.service_id)
                commit('createSession', conversation)
            }
        }
        commit('orderSessions')
        commit('setLoading', false)
        commit("setTemporaryLoader", 0)
    },

    async getPendingsCount({ rootState, commit, state }, service_id) {
        let params = {
            service_id: service_id || router.app._route.params.service_id,
        }
        const count = await RasaSessionsService.get_sessions_pending_count(params).then(res => res.data)
        commit('updatePendingsCount', count)
    },

    async registerConnection({ rootState, commit, state }, payload){
        commit('setLoading', true)
        this._vm.$socket.emit('register_connection', payload)
    },

    async registerSession({ rootState, commit, state }, id){
        commit('setLoading', true)
        let payload = {
            room: id
        }
        this._vm.$socket.emit('register_session', payload)
    },


    async getNextSession({ rootState, commit, state, dispatch }) {
        let params = {
            service_id: router.app._route.params.service_id,
        }

        if (state.prioritySessionsSource != null) {
            params.source = state.prioritySessionsSource
        }
        
        if (state.qtdSessionsToPush != null) {
            params.qtd_to_push = state.qtdSessionsToPush
        }

        let payload = await RasaSessionsService.get_next_session(params).then(res => res.data)
        if (payload.sessions.length > 0) {
            commit('showSnackbar', `${payload.sessions.length} conversas encontradas.`, {root: true})
            await dispatch('getSessions', payload)
            
            payload.sessions.forEach(async (item) => {
                await dispatch('registerSession', item.id)
                
            })

            const idx = state.sessions.indexOf(state.sessions.find((obj) => obj.session.id == payload.sessions[0].id))
            dispatch('rasaConversation/changeCurrentConversation', state.sessions[idx], { root: true })
        } else {
            commit('showSnackbar', `Nenhuma conversa encontrada.`, {root: true})
        }
    },

    async showWaitingTreatment({ rootState, commit, state, dispatch }) {
        const idx = state.sessions.indexOf(state.sessions.find((conversation) => conversation.session.status == "WAITING_TREATMENT"))
        if (idx > -1) {
            dispatch('rasaConversation/changeCurrentConversation', state.sessions[idx], { root: true })
        } else {
            dispatch('rasaConversation/clearCurrentConversation', {},{ root: true })
        }
    },

    async getWaitingTreatment({ rootState, commit, state, dispatch }) {
        const params = {
            fields_only: 'id',
            status: 'WAITING_TREATMENT',
            service_id: router.app._route.params.service_id
        }
        const response = await RasaSessionsService.get_my_sessions_by_status(params).then(res => res.data)
        await dispatch('getSessions', response)
    },

    async assignToMe({ rootState, commit, state }, id) {
        await RasaSessionsService.assign_to_me(id).then(res => res.data)
    },

    async basicChangeSessionStatus({ rootState, commit, state, dispatch}, payload) {
        let session = payload.session
        let new_status = payload.new_status

        await RasaSessionsService.patch_sessions_status(session.id, {status: new_status}).then((res) => {
            commit('showSnackbar', `Sessão ${session.id} atualizado com sucesso`, {root: true})
        }).catch((res) => {
            commit('showSnackbar', `Falha ao atualizar sessão ${session.id}!`, {root: true})
        })
    },

    async changeSessionSource({ rootState, commit, state, dispatch }, newSource){
        await RasaSessionsService.patch_session_source(rootState.rasaConversation.currentConversation.session.id, {source: newSource}).then((res) => {
            router.go()
        }).catch((res) => {
            commit('showSnackbar', `Falha ao atualizar sessão ${rootState.rasaConversation.currentConversation.session.id}!`, {root: true})
        })
    },

    async changeSessionStatus({ rootState, commit, state, dispatch }, payload) {
        let session = payload[0]
        let new_status = payload[1]
        let idx = state.sessions.indexOf(state.sessions.find((conversation) => conversation.session.id == session.session.id))
        if (idx == -1) {
            idx = rootState.rasaClosedSessions.closedSessions.indexOf(
                rootState.rasaClosedSessions.closedSessions.find((conversation) => conversation.session.id == session.session.id)
                )
        }
        if (idx > -1 && state.statusToShow != 'CLOSING') {commit('deleteSession', idx)}
        if (idx > -1 && state.statusToShow == 'CLOSING') {
            dispatch('rasaClosedSessions/removeClosedConversation', idx,{ root: true })
        }
        RasaSessionsService.patch_sessions_status(session.session.id, {status: new_status}).then(res => res.data)
        
        if (new_status == 'WAITING_TREATMENT') {
            session.session.status = new_status
            commit('createSession', session)
            commit('orderSessions')
        }
        
        dispatch('getPendingsCount', null)
        
        if (idx > -1) {
            let next = state.sessions.slice(idx).find((obj) => obj.session.status == state.statusToShow)
            if (next) {
                dispatch('rasaConversation/changeCurrentConversation', next, { root: true })
                return
            }
            else if (state.statusToShow == 'CLOSING') {
                next = rootState.rasaClosedSessions.closedSessions.slice(idx).find((obj) => obj.session.status == state.statusToShow)
                if (next) {
                    dispatch('rasaConversation/changeCurrentConversation', next, { root: true })
                    dispatch('rasaConversation/clearCurrentConversation', {}, { root: true }) 
                    return
                }
            }
        }
        // router.go() 
        dispatch('rasaConversation/clearCurrentConversation', {}, { root: true }) 
    },

    async backToNormal({ rootState, commit, state, dispatch }) {
        const idx = state.sessions.indexOf(state.sessions.find((conversation) => conversation.session.status == "PENDING"))
        if (idx > -1) {
            dispatch('rasaConversation/changeCurrentConversation', state.sessions[idx], { root: true }) 
        }
        else {
            dispatch('rasaConversation/clearCurrentConversation', {}, { root: true }) 
        }
    },
    
    async editSession({ rootState, commit, state, dispatch }, payload) {
        let session = payload[0]
        let data = payload[1]
        
        await RasaSessionsService.patch_sessions_by_id(session.session.id, data).then(res => res.data)
        
        let idx = state.sessions.indexOf(state.sessions.find((obj) => obj.session.id == session.session.id))
        if (idx > -1) {commit('deleteSession', idx)}

        if (data.user_id == rootState.auth.loggedUser.id) {
            await dispatch('getSessions', {sessions:[{id: session.session.id}]})
            idx = state.sessions.indexOf(state.sessions.find((obj) => obj.session.id == session.session.id))
            dispatch('rasaConversation/changeCurrentConversation', state.sessions[idx], { root: true })

        } else if (state.sessions.length != -1) {
            dispatch('rasaConversation/changeCurrentConversation', state.sessions[0], { root: true })

        } else {
            dispatch('rasaConversation/clearCurrentConversation', {},{ root: true })
        }
    },

    async checkExpiration({ rootState, commit }) {
        await RasaSessionsService.check_expiration(rootState.rasaConversation.currentConversation.session.id).then((res) => {
            if (res.data.expired == false) {
                router.go()
            } else {
                commit('showSnackbar','Api informou que esta conversa esta expirada, chame um TI pf!', {root:true})
            }
        })
    },

    async sessionWasReaded({ rootState, commit, state, dispatch }, session_id) {
        let idx = state.sessions.indexOf(state.sessions.find((obj) => obj.session.id == session_id))
        
        if (idx > -1) {
            let messages_unread = state.sessions[idx].messages.filter(item => {
                return item.processed == null && item.type == 'received'
            })
            messages_unread.forEach(async (item) => {
                await RasaConversationService.mark_as_read(item.id).then(res => {res.data})
            })
            commit('setHasUnread', {idx: idx, has_unread: false})
        }
    },
    async socketRasa_registered_connection({ rootState, commit, dispatch }, payload) {
        console.log('connected to Rasa socket')
        await dispatch('getSessions', payload)
        
        if (!router.app._route.params.conversation_id) {
            const idx = state.sessions.indexOf(state.sessions.find((conversation) => conversation.session.status == "PENDING"))
            if (idx > -1) {
                router.push(
                  `/customerService2/${rootState.rasaServices.currentService.id}/conversation/${state.sessions[idx].session.id}`
                ).catch(() => { })
            } else {
                router.push(
                  `/customerService2/${rootState.rasaServices.currentService.id}/conversation/${null}`
                ).catch(() => { })
            }
        } else {
            dispatch('rasaConversation/buildConversation', {}, { root: true }) 
        }
    },

    socketRasa_already_connected({ dispatch, rootState }) {
        if (router.app._route.params.service_id) {
            dispatch('registerConnection', {service_id: router.app._route.params.service_id})
        } else if (rootState.rasaServices.currentService) {
            dispatch('registerConnection', {service_id: rootState.rasaServices.currentService.id})
        }
    },

    socketRasa_atualizar_pendentes({ dispatch }) {
        dispatch('getPendingsCount', null)
    },

	socketRasa_atender_sessao({ dispatch }, payload) {
		dispatch('getSessions', payload)
	},

	socketRasa_reload_all({ commit, dispatch }, payload) {
        localStorage.removeItem('DODO_TOKEN') // if the request fails, remove any possible user token if possible
        localStorage.removeItem('DODO_USER')

		router.go()
        commit('showSnackbar', payload['payload'], {root: true})
	},

    socketRasa_mensagem_processada({ rootState, state, commit, dispatch }, payload) {
        console.log('Processando mensagem')
        const session = state.sessions.find((conversation) => {
            return conversation.session.id == payload.session_id
        })
        if (session) {
            if (session.messages.length < 2) {
                let session_id = session.session.id
                let idx_s = state.sessions.indexOf(state.sessions.find((obj) => obj.session.id == session_id))
                commit('deleteSession', idx_s)
                dispatch('getSessions', {"sessions": [{"id": session_id}]})
            } else {
                commit('updateLastMessage', payload)
                const message_exist = session.messages.find((message) => {
                    return message.id == payload.id && message.type == payload.type
                })
                if (!message_exist) {
                    commit('pushMessage', payload)
                    if (payload.session_id != rootState.rasaConversation.currentConversation.session.id) {
                        let idx = state.sessions.indexOf(state.sessions.find((obj) => obj.session.id == payload.session_id))
                        commit('setHasUnread', {idx: idx, has_unread: true})
                    }
                }
            }
        } else if (payload.session_id == rootState.rasaConversation.currentConversation.session.id) {
            commit('rasaConversation/pushMessage', payload, { root: true })
        } else if (payload.session.user_id == rootState.auth.loggedUser.id) {
            dispatch('getSessions', {"sessions": [{"id": payload.session_id}]})
        }
    },

    socketRasa_atualizar_status_mensagem({ rootState, commit, dispatch }, payload) {
        let message = payload.payload
        let idx = state.sessions.indexOf(state.sessions.find((obj) => obj.session.id == message.session_id))
        
        if (idx > -1) {
            commit('setMessageStatus', { idx:idx, message: message })
        }
        if (message.session_id == rootState.rasaConversation.currentConversation.session.id) {
            commit('rasaConversation/setMessageStatus', message, { root: true })   
        }
    },
}

const getters = {
  sessions: (state) => state.sessions,
  sessionsPendingCount: (state) => state.sessionsPendingCount,
  loading: (state) => state.loading,
  statusToShow: (state) => state.statusToShow,
  lastCallCountTime: (state) => state.lastCallCountTime,
  temporaryLoader: (state) => state.temporaryLoader,
  prioritySessionsSource: (state) => state.prioritySessionsSource,
  qtdSessionsToPush: (state) => state.qtdSessionsToPush,
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};