import RasaSessionsService from '@/services/sessions'
import RasaConversationService from '@/services/conversation'

export async function mountMessagesBySession(session) {
    if (session.messages.length == 0) {
        const params = {
            session_id: session.session.id
        }
        const messages_received = await RasaConversationService.get_received_messages(params).then(res => res.data)
        const messages_sent = await RasaConversationService.get_sent_messages(params).then(res => res.data)

        let session_status_history = []

        try {
            session_status_history = await RasaConversationService.get_session_status_history(session.session.id).then(res => res.data)
        } catch (error) {
            console.error('Não foi possível carregar o histórico de status da sessão', error)
        }

        const messages = messages_received.messages.concat(messages_sent.messages).concat(session_status_history)
        let messages_sorted = []
        if (messages.length > 0) {
            messages_sorted = messages.sort((message1, message2) => {
                if(message1.created_at < message2.created_at) return -1
                return 1
            })
        }
        return messages_sorted
    } else {return session.messages}
}


export async function mountMessagesBySender(sender, service_id) {
    const params = {
        sender: sender,
        service_id: service_id
    }

    const messages_received = await RasaConversationService.get_received_messages(params).then(res => res.data)
    const messages_sent = await RasaConversationService.get_sent_messages(params).then(res => res.data)
    const messages = messages_received.messages.concat(messages_sent.messages)
    let messages_sorted = []
    if (messages.length > 0) {
        messages_sorted = messages.sort((message1, message2) => {
            if(message1.created_at < message2.created_at) return -1
            return 1
        })
    }
    return messages_sorted
}

export async function mountSessionById(session_id, service_id) {
    let conversation = {}
    conversation['session'] = await RasaSessionsService.get_session_by_id(session_id).then(res => res.data)
    if (service_id != conversation['session']['service_id']) {
        return null;
    }
    conversation['last_message'] = await RasaSessionsService.get_last_message(session_id).then(res => res.data)
    conversation['messages'] = []
    
    if (!conversation.session.customer) {
        conversation.session.customer = {
            name: null,
            document: null,
            crm_ref: null,
        }
    }
    return conversation
  }