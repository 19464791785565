import axios from '@/plugins/axios'

export default {

    get_received_messages(params) {
        return axios.rasaApi.get(`/message_received`, { params })
    },

    get_sent_messages(params) {
        return axios.rasaApi.get(`/message_sent`, { params })
    },

    get_last_message(session_id) {
        return axios.rasaApi.get(`/session/${session_id}/last_message`)
    },

    get_session_status_history(session_id) {
        return axios.rasaApi.get(`/session/${session_id}/status_history`)
    },

    send_message(message) {
        return axios.rasaApi.post(`/message_sent`, message)
    },

    send_quick_reply(key, data) {
        console.log(data)
        return axios.rasaApi.post(`/quick_reply/${key}/send`, data)
    },

    send_initiative(initiative, service_key) {
        return axios.rasaApi.post(`/initiative/${service_key}/dispatch`, initiative)
    },

    mark_as_read(message_id) {
        return axios.rasaApi.patch(`/message_received/${message_id}/processed`)
    },

    mark_classification_as_wrong(message_id) {
        return axios.rasaApi.patch(`/message_received/${message_id}/bad_classification`)
    },

    get_intents_until_now() {
        return axios.rasaApi.get(`/message_received/intents_recognized`)
    },
}

